
import { dispatchGetNewsList } from '@/store/news/actions';
import { readNewsList } from '@/store/news/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class NewsList extends Vue {
  public perPage = 10;
  public page = 1;
  public loadingState: any = {
    page: true,
  };

  public async mounted() {
    const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
    await dispatchGetNewsList(this.$store, {rowQuery});
    this.loadingState.page = false;
  }

  public handleChangePage(page) {
    if (this.page != page) {
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
    }
  }

  get newsResponse() {
    return readNewsList(this.$store);
  }
}
